import React, {useState} from "react";
import images from "../Images/images";
import "../CSS/Tabs.css";
import "../CSS/Responsive.css";
import LazyLoad from "react-lazy-load";

const Tab = ({tabs}) => {
    const {constIdea, tabsData} = tabs;
    const [activeTab, setActiveTab] = useState(0);
    console.log(tabsData)
    const handleTabChange = (index) => {
        setActiveTab(index);
    };


    const projects = [
        {
            id: 1,
            name: "Go Sportified",
            categories: ["Web", "Mobile", "Backend"],
            description: "Go Sportified is a dynamic sports-based application designed to bring sports enthusiasts, match organizers, and various sports communities together on a single platform. Users can browse and view details of ongoing and upcoming matches, ensuring they never miss out on their favorite sports events. Organizers have the ability to seamlessly add new matches, update stadium details, and manage event information. The application also fosters interaction between users, organizers, and sports communities, creating a vibrant environment for discussions, updates, and networking. Whether you`re a fan, a player, or an organizer, Go Sportified offers a comprehensive and interactive experience, keeping you connected to the sports world.",

            status: "Completed",
            img: images.sportified
        },
        {
            id: 2,
            name: "Popup • Business",
            categories: ["Web", "Mobile", "Cloud", "Backend", "Artificial Intelligence", "MVP Development"],
            description: "PopUp is a dynamic multi-feature application designed to revolutionize the entertainment, business, social, and community experience. This innovative platform brings together a wide range of functionalities, offering users the ability to seamlessly engage with entertainment content, network with business professionals, and connect with their community in meaningful ways. With features that include live streaming, event management, social networking, and community building tools, PopUp provides a versatile and immersive experience for its users. Whether it`s hosting virtual events, discovering trending entertainment, or fostering social interactions, PopUp stands out as a comprehensive solution that caters to diverse needs, making it an essential app for anyone looking to stay connected and engaged in today`s digital age.",
            status: "Completed",
            img: images.popupImage
        },
        {
            id: 3,
            name: "Uniapp  • Indrive -- Share your Ride",
            categories: ["Web", "Mobile", "Backend"],
            description: "Uniapp is a SaaS-based ride-sharing application designed to offer a seamless and efficient transportation experience, similar to the popular service Indrive. The platform connects drivers and passengers in real-time, enabling users to book rides easily and affordably. Uniapp focuses on flexibility, allowing passengers to negotiate fares directly with drivers, ensuring fair pricing for both parties. With features like real-time tracking, secure payment options, and user ratings, Uniapp aims to provide a reliable and convenient alternative to traditional ride-sharing services, enhancing urban mobility while promoting safety and satisfaction for all users.",
            status: "Completed",
            img: images.uniApp
        },
        {
            id: 4,
            name: "Artur",
            categories: ["Web", "Mobile", "Backend"],
            description: "Artur is an innovative event management platform that leverages the power of maps to connect users with events worldwide. Through Artur, users can easily discover and register for various events, ranging from concerts and festivals to conferences and community gatherings, all directly through an interactive map interface. This unique approach allows users to explore events based on their geographic location, interests, and preferences, providing a seamless and engaging way to participate in global experiences. Whether it`s finding a local art exhibition or joining a major international conference, Artur simplifies the process, making event participation accessible and enjoyable for everyone.",
            status: "Completed",
            img: images.arturImage
        },
        {
            id: 5,
            name: "Karmat Sons",
            categories: ["Web", "Mobile", "Backend"],
            description: "Karamat Sons is an e-commerce platform transformed from a traditional local shop to meet the growing demand for high-quality ladies` shawls, dupattas, and abayas. The online store offers a curated selection of elegant and fashionable pieces that cater to a diverse range of tastes and preferences. By transitioning to an online platform, Karamat Sons provides customers with the convenience of shopping from the comfort of their homes while maintaining the same level of quality and personalized service that the local shop was known for. With detailed product descriptions, vibrant images, and a user-friendly interface, Karamat Sons aims to make luxury and traditional attire accessible to a broader audience, blending cultural heritage with modern shopping convenience.",
            status: "Ongoing",
            img: images.karmatSons
        },
        {
            id: 6,
            name: "ShopRex",
            categories: ["Web", "Mobile", "Backend"],
            description: "Shoprex is an exclusive e-commerce platform specializing in ladies` suits and fashion wear, offering a curated selection of high-quality fabrics, elegant designs, and stylish accessories. Catering to modern women who seek both tradition and contemporary fashion, Shoprex provides a seamless online shopping experience, featuring a diverse range of suits, including casual, formal, and party wear. With detailed product descriptions, vibrant images, and easy navigation, customers can explore and purchase their favorite outfits from the comfort of their homes. Shoprex aims to redefine convenience and style in online shopping, ensuring customer satisfaction through quality products and excellent service.",
            status: "Ongoing",
            img: images.shopRex
        },
        {
            id: 7,
            name: "Nomad Tales",
            categories: ["Mobile"],
            description: "Nomad Tales is a travel-based application designed to inspire and connect travelers worldwide. The platform offers a unique blend of travel stories, tips, and recommendations, allowing users to explore new destinations, share their experiences, and connect with like-minded travelers. Nomad Tales features a user-friendly interface, interactive maps, and curated content, making it easy for users to discover hidden gems, plan their trips, and engage with a vibrant travel community. Whether you`re a seasoned globetrotter or a novice explorer, Nomad Tales provides a platform to share your adventures, seek inspiration, and connect with fellow travelers, creating a virtual space for travel enthusiasts to come together and celebrate the joy of exploration.",
            status: "Completed",
            img: images.nomadTales
        },
        {
            id: 8,
            name: "Natter",
            categories: ["Mobile", "Backend"],
            description: "Natter is a social networking application designed to foster meaningful conversations and connections among users. The platform offers a range of features, including public and private chat rooms, group discussions, and topic-based forums, allowing users to engage with diverse communities and explore shared interests. Natter focuses on user privacy and security, providing a safe and inclusive space for users to interact, share ideas, and build relationships. Whether you`re looking to connect with like-minded individuals, discuss trending topics, or simply have fun chatting with friends, Natter offers a versatile and engaging platform for social networking and community building.",
            status: "Completed",
            img: images.natter
        },
        {
            id: 9,
            name: "Nephron AI",
            categories: ["Mobile", "Backend"],
            description: "Nephron AI is a healthcare application designed to streamline the process of diagnosing and managing kidney-related diseases. The platform leverages artificial intelligence and machine learning algorithms to analyze patient data, identify risk factors, and provide personalized treatment recommendations. Nephron AI aims to improve patient outcomes, reduce healthcare costs, and enhance the efficiency of kidney disease management. By combining cutting-edge technology with medical expertise, Nephron AI offers a comprehensive solution for healthcare providers and patients, ensuring timely and accurate diagnosis, treatment, and monitoring of kidney-related conditions.",
            status: "Completed",
            img: images.nephronAI
        },
    ]
    return (
        <div className="tab-container">
            <div className={`tab-buttons ${constIdea ? "portfolioTab" : "mainTab"}`}>
                {tabsData.map((tab, index) => (
                    <React.Fragment>
                        <button
                            key={index}
                            className={activeTab === index ? "active" : "tab_btn"}
                            onClick={() => handleTabChange(index)}
                        >
                            {tab.tabTitle}
                        </button>
                        <span
                            className={`${
                                tabsData.length - 1 === index ? "last" : "btn_border"
                            }`}
                        ></span>
                    </React.Fragment>
                ))}
            </div>

            {constIdea && (
                <div className="tabconstIdea" style={{margin: "12rem 0rem"}}>
                    {constIdea()}
                </div>
            )}
            {constIdea &&
                <div className="grid">
                    {
                        projects.filter((project) => project.categories.includes((tabsData[activeTab].tabTitle))).length > 0 ? (
                            projects.filter((project) => project.categories.includes((tabsData[activeTab].tabTitle))).map((project) => (
                                <div key={project.id}>
                                    <img className={" projectImgs rounded-5"} src={project.img} alt={project.name}/>
                                    <div style={{fontSize: "3rem", fontWeight: 800}} className={"my-3"}>
                                        {project.name}
                                    </div>
                                    <div style={{fontSize: "1.8rem"}}>
                                        {project.description}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div style={{fontSize:"4rem"}}>
                                Coming Soon
                            </div>
                        )}

                </div>
            }
            {/*{constIdea && (*/}
            {/*    <div className="tab-content">*/}
            {/*        {tabsData[activeTab].categories.map((category, index) => (*/}
            {/*            <LazyLoad className="lazyload">*/}
            {/*                <img*/}
            {/*                    className={"portfolio_images"}*/}
            {/*                    key={index}*/}
            {/*                    src={images[category]}*/}
            {/*                    alt={tabsData[activeTab].tabTitle}*/}
            {/*                    // style={{ width: `${tabsData[activeTab].width}` }}*/}
            {/*                />*/}
            {/*            </LazyLoad>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*)}*/}

            {constIdea || (
                <div className="tab-content right">
                    {tabsData[activeTab].categories.map((category, index) => (
                        <span
                            style={{
                                fontSize: `${tabsData[activeTab].width}`,
                                marginRight: "2rem",
                                borderLeft: "1px solid #FF4137",
                                marginBottom: "1.9rem",
                                paddingLeft: "1rem",
                                textAlign: 'left',
                                alignContent: 'left',
                                alignSelf: 'left',
                                justifyContent: 'left',
                                marginLeft: '0',
                                left: 0,
                            }}
                            className="textLeft"
                        >
              {category}
            </span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Tab;
