import React, { useState } from "react";
import Images from "../Images/images";
import Logo from "../Images/Logo SVG/Sumizan Logo SVG.svg";
import "../CSS/Main.css";
import "../CSS/Common.css";
import "../CSS/Header.css";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";

const SideBar = ({ showSidebar, setShowSidebar }) => {
  return (
    <div
      className="sidebar1"
      style={{
        transition: "0.3s ease-in-out",
        transform: `${
          showSidebar === true ? "translateX(0%)" : "translateX(100%)"
        }`,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "30px",
          right: "20px",
          fontSize: "30px",
          cursor: "pointer",
        }}
        onClick={() => setShowSidebar(false)}
      >
        &times;
      </div>
      <div className="brand" style={{ transform: "translateX(-30px)" }}>
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <span>
        <Link
          to="/"
          style={{
            textDecoration: "none",
          }}
          onClick={() => setShowSidebar(false)}
        >
          Home
        </Link>
      </span>
      <span>
        <Link
          to="/about"
          style={{ textDecoration: "none" }}
          onClick={() => setShowSidebar(false)}
        >
          About
        </Link>
      </span>
      <span>
        <Link
          to="/services"
          style={{ textDecoration: "none" }}
          onClick={() => setShowSidebar(false)}
        >
          Services
        </Link>
      </span>
      <span>
        <Link
          to="/portfolio"
          style={{ textDecoration: "none" }}
          onClick={() => setShowSidebar(false)}
        >
          Portfolio
        </Link>
      </span>
      <span>
        <Link
          to="/contact"
          style={{ textDecoration: "none" }}
          onClick={() => setShowSidebar(false)}
        >
          Contact
        </Link>
      </span>
    </div>
  );
};
const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <React.Fragment>
      <nav className="header">
        <SideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        <div className="brand">
          <img src={Logo} alt="logo" className="logo" />
        </div>
        <ul className="headLinks">
          <li>
            <Link to="/" style={{ textDecoration: "none" }} className="link">
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              style={{ textDecoration: "none" }}
              className="link"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              style={{ textDecoration: "none" }}
              className="link"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/portfolio"
              style={{ textDecoration: "none" }}
              className="link"
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              style={{ textDecoration: "none" }}
              className="link"
            >
              Contact
            </Link>
          </li>
        </ul>
        <IoMenu
          className="list-icon"
          style={{ cursor: "pointer" }}
          onClick={() => setShowSidebar(!showSidebar)}
        />
      </nav>
    </React.Fragment>
  );
};

export default Header;
